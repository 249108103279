import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/UI/Shadcn/Card";
import { Checkbox } from "../../../components/UI/Shadcn/Checkbox";
import { Label } from "../../../components/UI/Shadcn/Label";
import { Button } from "../../../components/UI/Shadcn/Button";
import { GripVertical, Loader2, Info } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/UI/Shadcn/Tooltip";
import { ScrollArea } from "../../../components/UI/Shadcn/ScrollArea";
import { useToast } from "../../../hooks/use-toast";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../../features/auth/authSlice";

export default function ImpactFactorsManager({
  data,
  trainingDoeId,
  onExperimentalDesignsGenerated,
  isCreatingExperimentalDesigns,
  isViewOnly,
  onCloseModal,
}) {
  const [impactFactors, setImpactFactors] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const { toast } = useToast();
  const accessToken = useSelector(selectCurrentToken);

  // console.log(data, "💪💪💪💪");

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setImpactFactors(data);
    }
  }, [data]);

  const onDragStart = (e, index) => {
    setDraggedItem(impactFactors[index]);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", index);
    e.target.style.opacity = "0.5";
  };

  const onDragEnd = (e) => {
    e.target.style.opacity = "1";
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
    const draggedOverItem = impactFactors[index];
    if (draggedItem === draggedOverItem) return;
    setDragOverIndex(index);
  };

  const onDrop = (e, index) => {
    e.preventDefault();
    const draggedItemIndex = impactFactors.indexOf(draggedItem);
    const newImpactFactors = [...impactFactors];
    newImpactFactors.splice(draggedItemIndex, 1);
    newImpactFactors.splice(index, 0, draggedItem);
    setImpactFactors(newImpactFactors);
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const toggleFactorSelection = (factor) => {
    setSelectedFactors((prev) => {
      const isSelected = prev.some(
        (f) => f.impactFactorId === factor.impactFactorId
      );
      if (isSelected) {
        return prev.filter((f) => f.impactFactorId !== factor.impactFactorId);
      } else {
        return [...prev, factor];
      }
    });
  };

  const handleCreateExperimentalDesigns = async () => {
    try {
      const orderedSelectedFactors = impactFactors.filter((factor) =>
        selectedFactors.some(
          (selected) => selected.impactFactorId === factor.impactFactorId
        )
      );

      const impactFactorIds = orderedSelectedFactors.map(
        (factor) => factor.impactFactorId
      );
      console.log("Selected impactFactorIds:", impactFactorIds);
      console.log("Selected trainingDoeId:", trainingDoeId);

      // Realizar la solicitud POST al endpoint
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/generate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Si necesitas un token de autenticación
          },
          body: JSON.stringify({
            trainingDoeId: trainingDoeId,
            impactFactorIds: impactFactorIds,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate experimental designs");
      }

      const result = await response.json();
      console.log("Experimental designs generated successfully:", result);
      await onExperimentalDesignsGenerated(orderedSelectedFactors);

      toast({
        title: "Success",
        description: "Experimental designs generated successfully.",
        variant: "success",
      });
      onCloseModal();
    } catch (error) {
      console.error("Error generating experimental designs:", error);
      toast({
        title: "Error",
        description:
          "Failed to generate experimental designs. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Card className="w-full bg-card">
        <CardHeader>
          <CardTitle>Impact Factors</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground">
            No impact factors available.
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full bg-card">
      <CardHeader>
        <CardTitle>Select and Order Impact Factors</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          <ul className="space-y-2">
            {impactFactors.map((factor, index) => (
              <li
                key={factor.impactFactorId}
                draggable={!isViewOnly}
                onDragStart={(e) => onDragStart(e, index)}
                onDragEnd={onDragEnd}
                onDragOver={(e) => onDragOver(e, index)}
                onDrop={(e) => onDrop(e, index)}
                className={`flex items-center space-x-2 p-2 rounded-md border ${
                  dragOverIndex === index ? "border-primary" : "border-input"
                } transition-colors duration-200 bg-background`}
              >
                {!isViewOnly && (
                  <GripVertical className="h-5 w-5 text-muted-foreground cursor-move" />
                )}
                <Checkbox
                  id={`checkbox-${factor.impactFactorId}`}
                  checked={selectedFactors.some(
                    (f) => f.impactFactorId === factor.impactFactorId
                  )}
                  onCheckedChange={() => toggleFactorSelection(factor)}
                  disabled={isViewOnly}
                />
                <div className="flex-grow">
                  <Label
                    htmlFor={`checkbox-${factor.factor}`}
                    className="text-sm font-medium"
                  >
                    {factor.factor}
                  </Label>
                  <p className="text-sm text-muted-foreground">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Info className="h-4 w-4 mr-2 inline-block text-muted-foreground cursor-help" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            {factor.notes ||
                              "No additional information available."}
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    Optimal: {factor.minAvg} - {factor.maxAvg} {factor.unit}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </ScrollArea>
        {!isViewOnly && (
          <Button
            onClick={handleCreateExperimentalDesigns}
            className="mt-4 w-full bg-primary text-primary-foreground"
            disabled={
              selectedFactors.length === 0 || isCreatingExperimentalDesigns
            }
          >
            {isCreatingExperimentalDesigns ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Creating Experimental Designs...
              </>
            ) : (
              "Create Experimental Designs"
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
