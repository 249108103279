//src/features/bion/bionApiSlice.js

import { apiSlice } from "../../app/api/apiSlice";
import pusher from "../../app/pusher";

export const bionApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Gets the real-time distance between the user and the specified BION
    getUserDistanceFromBion: build.query({
      query: ({ bionId, longitude, latitude }) =>
        `/bions/request-position?bionId=${bionId}&longitude=${longitude}&latitude=${latitude}`,
    }),

    // Retrieves data for a single BION based on its ID
    getSingleBion: build.query({
      query: (bionId) => `/bions?bionId=${bionId}`,
    }),

    // Fetches the list of users bound to the given BION ID
    getBionUsers: build.query({
      query: (bionId) => `/bions/users?bionId=${bionId}`,
    }),

    // Returns a list of BIONs that are bound and ready to use or being used by the user
    getReadyBions: build.query({
      query: () => "/bions/ready",
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        }
      ) {
        const userId = getState().auth.user.userId;
        const userName = getState().auth.user.userName;

        // Create a Pusher connection when the cache subscription starts
        pusher.initializePusher();

        let bions = [];

        try {
          // Wait for the initial query to resolve before proceeding
          ({ data: bions } = await cacheDataLoaded);

          // Subscribe to Pusher channels and Bind to events
          pusher.subscribeToChannels(
            {
              channelName: `private-machine-status-channel-${userId}`,
              eventName: "machine-status-changed",
            },
            ({ bionId, ...receivedData }) => {
              updateCachedData((bions) => {
                const bionToUpdate = bions.find(
                  (bion) => bion.bionId === bionId
                );
                const bionToUpdateIdx = bions.findIndex(
                  (bion) => bion.bionId === bionId
                );
                for (const key in receivedData) {
                  bionToUpdate[key] = receivedData[key];
                }

                if (!("bionMode" in receivedData)) {
                  bionToUpdate.bionMode = null;
                }

                if (!bionToUpdate.isOn && !bionToUpdate.shutDownDate) {
                  bions.splice(bionToUpdateIdx, 1);
                }
              });
            }
          );

          pusher.subscribeToChannels(
            {
              channelName: `private-user-linked-to-bion-${userName}`,
              eventName: "user-linked-to-bion",
            },
            (receivedBion) => {
              updateCachedData((bions) => {
                // Check if there's already a bion with the same bionId
                const existingBion = bions.find(
                  (bion) => bion.bionId === receivedBion.bionId
                );
                if (!existingBion) {
                  // If no bion with the same bionId exists, add the received bion
                  bions.push(receivedBion);
                } else {
                  // If a bion with the same bionId exists, handle it accordingly (optional)
                  console.log(
                    `A bion with bionId ${receivedBion.bionId} already exists.`
                  );
                }
              });
            }
          );

          pusher.subscribeToChannels(
            {
              channelName: `private-bion-sterilise-channel-${userId}`,
              eventName: "bion-sterilise-changed",
            },
            ({ bionId, bionMode }) => {
              updateCachedData((bions) => {
                const bionToUpdate = bions.find(
                  (bion) => bion.bionId === bionId
                );
                bionToUpdate.bionMode = bionMode;
              });
            }
          );

          pusher.subscribeToChannels(
            {
              channelName: `private-process-changed-channel-${userId}`,
              eventName: "process-changed",
            },
            ({ bionId, bionMode }) => {
              updateCachedData((bions) => {
                const bionToUpdate = bions.find(
                  (bion) => bion.bionId === bionId
                );
                bionToUpdate.bionMode = bionMode;
              });

              if (!bionMode) {
                setTimeout(() => {
                  dispatch(
                    apiSlice.util.invalidateTags([
                      { type: "Process", id: bionId },
                    ])
                  );
                }, 250);
              }
            }
          );

          bions.forEach((bion) => {
            pusher.subscribeToChannels(
              {
                channelName: `private-bion-disconnect-channel-${bion.bionId}`,
                eventName: "bion-disconnected",
              },
              ({ bionId, disconnect }) => {
                updateCachedData((bions) => {
                  if (disconnect) {
                    return bions.filter((bion) => bion.bionId !== bionId);
                  }
                });
              }
            );

            pusher.subscribeToChannels(
              {
                channelName: `private-sensors-calibration-channel-${bion.bionId}`,
                eventName: "sensors-calibration-changed",
              },
              (dataReceived) => {
                console.log("received", dataReceived);
                if (
                  dataReceived.calibrationFinished &&
                  dataReceived.createdDate
                ) {
                  dispatch(
                    apiSlice.util.updateQueryData(
                      "getReadyBions",
                      undefined,
                      (bions) => {
                        const bionIdx = bions.findIndex(
                          (curBion) => curBion.bionId === bion.bionId
                        );
                        bions[bionIdx].lastPhCalibrationDate =
                          dataReceived.createdDate;
                        bions[bionIdx].bionMode = dataReceived.bionMode;
                      }
                    )
                  );
                }
              }
            );
          });
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;

        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        pusher.unsubscribeFromChannels(
          `private-machine-status-channel-${userId}`,
          `private-user-linked-to-bion-${userName}`,
          `private-process-changed-channel-${userId}`,
          `private-bion-sterilise-channel-${userId}`
        );

        bions.forEach((bion) => {
          pusher.unsubscribeFromChannels(
            `private-bion-disconnect-channel-${bion.bionId}`
          );
        });
      },
    }),

    // Disconnects the user from the BION and finishes any running processes
    disconnectUserFromBion: build.mutation({
      query: (bionId) => ({
        url: "/bions/disconnect",
        method: "PUT",
        body: { bionId },
      }),
      async onQueryStarted(bionId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData(
              "getReadyBions",
              undefined,
              (bions) => {
                return bions.filter((bion) => bion.bionId !== bionId);
              }
            )
          );
        } catch (error) {}
      },
    }),

    // Retrieves the name of the specified BION
    getBionName: build.query({
      query: (bionId) => `/bions/name?bionId=${bionId}`,
    }),

    // Checks if the user is an ADMIN user for the given BION
    checkBionAdminStatus: build.query({
      query: (bionId) => `/bions/is-admin?bionId=${bionId}`,
    }),

    // Put Bion to sterilise mode
    putBionToSteriliseMode: build.mutation({
      query: (data) => ({
        url: "/bions/sterilise",
        method: "PUT",
        body: data,
      }),
    }),

    getCalibrationStatus: build.query({
      query: (bionId) => `/sensors/calibration?bionId=${bionId}`,
      async onCacheEntryAdded(
        bionId,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        }
      ) {
        // Create a Pusher connection when the cache subscription starts
        pusher.initializePusher();

        try {
          // Wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // Subscribe to Pusher channels and Bind to events
          pusher.subscribeToChannels(
            {
              channelName: `private-sensors-calibration-channel-${bionId}`,
              eventName: "sensors-calibration-changed",
            },
            (dataReceived) => {
              updateCachedData((drafts) => {
                const sensorToUpdate = drafts.find(
                  (sensor) => sensor.sensorType === "PH"
                );

                for (const key in dataReceived) {
                  sensorToUpdate[key] = dataReceived[key];
                }
              });

              if (
                dataReceived.calibrationFinished &&
                dataReceived.createdDate
              ) {
                dispatch(
                  apiSlice.util.updateQueryData(
                    "getReadyBions",
                    undefined,
                    (bions) => {
                      const bionIdx = bions.findIndex(
                        (bion) => bion.bionId === bionId
                      );
                      bions[bionIdx].lastPhCalibrationDate =
                        dataReceived.createdDate;
                    }
                  )
                );
              }
            }
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;

        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        pusher.unsubscribeFromChannels(
          `private-sensors-calibration-channel-${bionId}`
        );
      },
    }),

    // Update the sensors calibration status
    updateCalibration: build.mutation({
      query: (data) => ({
        url: "/sensors/calibration",
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetBionNameQuery,
  useLazyGetUserDistanceFromBionQuery,
  useGetReadyBionsQuery,
  useDisconnectUserFromBionMutation,
  usePutBionToSteriliseModeMutation,
  useGetCalibrationStatusQuery,
  useUpdateCalibrationMutation,
} = bionApiSlice;
