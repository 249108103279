import React, { useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { Button } from "../../../components/UI/Shadcn/Button";
import { Input } from "../../../components/UI/Shadcn/Input";
import { Loader2, FileIcon, Upload, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/UI/Shadcn/Dialog";
import { ScrollArea } from "../../../components/UI/Shadcn/ScrollArea";
import { Alert, AlertDescription } from "../../../components/UI/Shadcn/Alert";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/UI/Shadcn/Tooltip";
import { Card, CardContent } from "../../../components/UI/Shadcn/Card";
import { useToast } from "../../../hooks/use-toast";
import { useNavigate } from "react-router-dom";

const MAX_PDF_COUNT = 5;
const MAX_TOTAL_SIZE = 512 * 1024 * 1024; // 512MB in bytes

export default function TrainingNameModal({
  isOpen,
  onClose,
  trainingData,
  isLoading,
}) {
  const [trainingName, setTrainingName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState("");
  const [isCreatingTraining, setIsCreatingTraining] = useState(false);
  const fileInputRef = useRef(null);
  const accessToken = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!trainingName.trim()) {
      setError("Please enter a valid training name.");
      return;
    }

    setIsCreatingTraining(true);

    try {
      const formData = new FormData();

      // Agregar trainingDoeData actualizado con el trainingDoeName
      const updatedTrainingData = {
        ...trainingData,
        trainingDoeName: trainingName.trim(),
      };
      console.log("Training data with name:", updatedTrainingData);
      formData.append("trainingDoeData", JSON.stringify(updatedTrainingData));

      // Agregar archivos PDF al formData
      selectedFiles.forEach((file) => {
        formData.append("pdfs", file, file.name);
      });

      console.log("FormData before sending:", formData);

      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create new training.");
      }

      const result = await response.json();
      onClose();
      navigate(`/trainings/${result.trainingDoeId}`);
    } catch (error) {
      console.error("Error creating new training:", error);
      toast({
        title: "Error",
        description:
          error.message || "Failed to create new training. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsCreatingTraining(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.slice(0, 30);
    setTrainingName(value);
    if (value.length === 30) {
      setError("Maximum length reached (30 characters)");
    } else {
      setError("");
    }
  };

  const processFiles = (filesArray) => {
    const newSelectedFiles = [...selectedFiles];
    let hasError = false;

    if (newSelectedFiles.length + filesArray.length > MAX_PDF_COUNT) {
      setError(`Maximum ${MAX_PDF_COUNT} files allowed`);
      return;
    }

    const currentTotalSize = newSelectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    const newTotalSize = filesArray.reduce((acc, file) => acc + file.size, 0);
    if (currentTotalSize + newTotalSize > MAX_TOTAL_SIZE) {
      setError("Total file size exceeds 512MB limit");
      return;
    }

    filesArray.forEach((file) => {
      if (newSelectedFiles.some((f) => f.name === file.name)) {
        setError("File names must be unique");
        hasError = true;
      } else if (file.type !== "application/pdf") {
        setError("Only PDF files are allowed");
        hasError = true;
      } else {
        newSelectedFiles.push(file);
      }
    });

    if (!hasError) {
      setError("");
      setSelectedFiles(newSelectedFiles);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files) {
      processFiles(Array.from(event.target.files));
    }
  };

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      processFiles(Array.from(event.dataTransfer.files));
    },
    [selectedFiles]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleFileDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px] md:max-w-[700px] lg:max-w-[900px] p-0 overflow-hidden bg-card">
        <DialogHeader className="pt-6 px-6 pb-4 border-b">
          <DialogTitle className="text-2xl font-semibold">
            Create New Training
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="px-6 py-4 space-y-6">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="training-name"
                className="text-sm font-medium mb-2 block"
              >
                Training Name
              </label>
              <Input
                id="training-name"
                value={trainingName}
                onChange={handleInputChange}
                placeholder="Enter training name"
                maxLength={30}
                autoFocus
                className="w-full"
              />
              <p className="text-sm text-muted-foreground mt-2">
                {trainingName.length}/30 characters
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Card className="bg-card">
                <CardContent className="p-4">
                  <div
                    className="flex h-32 flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-primary bg-muted"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <Upload className="h-8 w-8 text-muted-foreground" />
                    <p className="text-sm font-medium">
                      Drag and Drop PDFs (Optional)
                    </p>
                    <Button
                      type="button"
                      onClick={() => fileInputRef.current?.click()}
                      variant="secondary"
                      size="sm"
                      className="bg-primary/80 hover:bg-primary text-background"
                    >
                      Upload PDFs
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      multiple
                      accept=".pdf"
                      onChange={handleFileChange}
                      onClick={(event) => {
                        event.target.value = "";
                      }}
                      className="hidden"
                    />
                  </div>
                  <p className="mt-2 text-xs text-center text-muted-foreground">
                    Max {MAX_PDF_COUNT} PDFs, up to 512MB total
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-card">
                <CardContent className="p-4">
                  <ScrollArea className="h-32 w-full">
                    {selectedFiles.length > 0 ? (
                      <ul className="space-y-2">
                        {selectedFiles.map((file, index) => (
                          <li
                            key={file.name}
                            className="flex items-center justify-between text-sm"
                          >
                            <div className="flex items-center gap-2">
                              <FileIcon className="h-4 w-4 text-muted-foreground" />
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <span className="font-medium truncate max-w-[150px]">
                                      {file.name}
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>{file.name}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                            <Button
                              type="button"
                              variant="ghost"
                              size="icon"
                              className="h-6 w-6"
                              onClick={() => handleFileDelete(index)}
                            >
                              <X className="h-4 w-4" />
                              <span className="sr-only">Delete file</span>
                            </Button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="flex h-full items-center justify-center">
                        <p className="text-sm text-muted-foreground">
                          No PDFs Uploaded Yet
                        </p>
                      </div>
                    )}
                  </ScrollArea>
                </CardContent>
              </Card>
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          </div>

          <div className="flex gap-3 pt-2">
            <Button
              type="submit"
              className="flex-1 bg-primary hover:bg-primary/90 text-background"
              disabled={isCreatingTraining || trainingName.trim().length === 0}
            >
              {isCreatingTraining ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Creating...
                </>
              ) : (
                "Create Training"
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
