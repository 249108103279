import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrentToken } from "../../features/auth/authSlice";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/UI/Shadcn/Card";
import {
  Loader2,
  AlertCircle,
  ChevronRight,
  Trash2,
  ArrowUpDown,
} from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../components/UI/Shadcn/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/UI/Shadcn/Table";
import { Button } from "../../components/UI/Shadcn/Button";
import { Badge } from "../../components/UI/Shadcn/Badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/UI/Shadcn/Select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/UI/Shadcn/Tooltip";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/UI/Shadcn/Pagination";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/UI/Shadcn/Breadcrumb";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/UI/Shadcn/Dialog";
import { useToast } from "../../hooks/use-toast";

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

export default function Trainings() {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    trainingId: null,
  });
  const [sortConfig, setSortConfig] = useState({
    key: "startDate",
    direction: "desc",
  });
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const accessToken = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const { toast } = useToast();

  const fetchData = useCallback(
    async (url) => {
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return response.json();
    },
    [accessToken]
  );

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const data = await fetchData(
          `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`
        );
        setTrainings(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTrainings();
  }, [fetchData]);

  const sortedTrainings = React.useMemo(() => {
    let sortableTrainings = [...trainings];
    if (sortConfig.key) {
      sortableTrainings.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableTrainings;
  }, [trainings, sortConfig]);

  const paginatedTrainings = sortedTrainings.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalPages = Math.ceil(sortedTrainings.length / pageSize);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handlePageSizeChange = (value) => {
    setPageSize(Number(value));
    setCurrentPage(1);
  };

  const openTrainingDetails = (trainingDoeId) => {
    navigate(`/trainings/${trainingDoeId}`);
  };

  const confirmDelete = (trainingDoeId) => {
    setDeleteConfirmation({ isOpen: true, trainingId: trainingDoeId });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, trainingId: null });
  };

  const deleteTraining = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify([
            { trainingDoeId: deleteConfirmation.trainingId },
          ]),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setTrainings(
        trainings.filter(
          (training) => training.trainingDoeId !== deleteConfirmation.trainingId
        )
      );
      toast({
        title: "Training Deleted",
        description: "The training has been successfully deleted.",
        variant: "success",
      });
    } catch (err) {
      setError(err.message);
      toast({
        title: "Error",
        description: "Failed to delete the training. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
      setDeleteConfirmation({ isOpen: false, trainingId: null });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-5 w-5" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  const SortableHeader = ({ column, label }) => (
    <TableHead>
      <button
        onClick={() => requestSort(column)}
        className="inline-flex items-center gap-1 hover:text-primary transition-colors"
      >
        {label}
        <ArrowUpDown className="h-4 w-4" />
      </button>
    </TableHead>
  );

  return (
    <div className="container mx-auto p-4 md:p-6 lg:p-8">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/new-files">
              Files
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Trainings</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Card className="mt-6">
        <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div>
            <CardTitle>Trainings</CardTitle>
            <CardDescription className="pt-2">
              Overview of all your training sessions
            </CardDescription>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">Show</span>
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="w-[100px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {PAGE_SIZE_OPTIONS.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </CardHeader>
        <CardContent>
          {paginatedTrainings.length === 0 ? (
            <div className="text-center py-6">
              <h3 className="text-2xl font-semibold mb-2">
                No Trainings Available
              </h3>
              <p className="text-muted-foreground">
                You currently don't have any trainings. Trainings will appear
                here once they are created.
              </p>
            </div>
          ) : (
            <>
              <div className="overflow-auto">
                <Table className="w-full">
                  <TableHeader>
                    <TableRow>
                      <SortableHeader column="trainingDoeName" label="Name" />
                      <SortableHeader column="startDate" label="Start Date" />
                      <SortableHeader column="finishDate" label="Status" />
                      <SortableHeader
                        column="processTypeDescription"
                        label="Process Type"
                      />
                      <SortableHeader
                        column="aimTypeDescription"
                        label="Aim Type"
                      />
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {paginatedTrainings.map((training) => (
                      <TableRow key={training.trainingDoeId}>
                        <TableCell className="font-medium max-w-[100px] sm:max-w-[200px] truncate">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <span className="block truncate max-w-[200px]">
                                  {training.trainingDoeName}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{training.trainingDoeName}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell className="max-w-[100px] sm:max-w-[150px] truncate">
                          {new Date(training.startDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell className="max-w-[100px] sm:max-w-[150px] truncate">
                          {training.finishDate ? (
                            <Badge
                              variant="outline"
                              className="bg-green-50 text-green-700 border-green-200"
                            >
                              Completed
                            </Badge>
                          ) : (
                            <Badge
                              variant="outline"
                              className="bg-blue-50 text-blue-700 border-blue-200"
                            >
                              In Progress
                            </Badge>
                          )}
                        </TableCell>
                        <TableCell className="max-w-[100px] sm:max-w-[200px] truncate">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <span className="block truncate max-w-[200px]">
                                  {training.processTypeDescription}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{training.processTypeDescription}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell className="max-w-[100px] sm:max-w-[200px] truncate">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <span className="block truncate max-w-[200px]">
                                  {training.aimTypeDescription}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{training.aimTypeDescription}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell className="text-right whitespace-nowrap">
                          <div className="flex justify-end space-x-2">
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() =>
                                openTrainingDetails(training.trainingDoeId)
                              }
                              className="hidden sm:inline-flex"
                            >
                              View
                              <ChevronRight className="ml-2 h-4 w-4" />
                            </Button>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() =>
                                openTrainingDetails(training.trainingDoeId)
                              }
                              className="sm:hidden"
                            >
                              <ChevronRight className="h-4 w-4" />
                            </Button>
                            <Button
                              variant="destructive"
                              size="icon"
                              onClick={() =>
                                confirmDelete(training.trainingDoeId)
                              }
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-center mt-4 gap-4">
                <div className="text-sm text-muted-foreground order-2 sm:order-1">
                  Showing {(currentPage - 1) * pageSize + 1} to{" "}
                  {Math.min(currentPage * pageSize, sortedTrainings.length)} of{" "}
                  {sortedTrainings.length} entries
                </div>
                <Pagination className="order-1 sm:order-2">
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious
                        onClick={() =>
                          setCurrentPage((p) => Math.max(1, p - 1))
                        }
                        disabled={currentPage === 1}
                      />
                    </PaginationItem>
                    <PaginationItem>
                      <Select
                        value={currentPage.toString()}
                        onValueChange={(value) => setCurrentPage(Number(value))}
                      >
                        <SelectTrigger className="w-[70px]">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {[...Array(totalPages)].map((_, i) => (
                            <SelectItem key={i + 1} value={(i + 1).toString()}>
                              {i + 1}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationNext
                        onClick={() =>
                          setCurrentPage((p) => Math.min(totalPages, p + 1))
                        }
                        disabled={currentPage === totalPages}
                      />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
            </>
          )}
        </CardContent>
      </Card>

      <Dialog open={deleteConfirmation.isOpen} onOpenChange={cancelDelete}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this training? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={cancelDelete}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={deleteTraining}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                "Delete"
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
